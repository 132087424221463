<template>
  <div class="admin-page">
    <!-- header -->
    <div class="d-flex align-center justify-space-between mt-5">
      <span class="font-32">Plans</span>
      <div class="d-flex align-center">
        <div class="d-flex align-center cursor-pointer mr-2" @click="newPlan">
          <span class="font-22 font-600">New Plan</span>
          <span
            class="font-30 d-inline-block px-3 white--text primary ml-2 radius-5"
            >+</span
          >
        </div>
        <!-- Edit Default Plan -->
        <!-- <div class="d-flex align-center cursor-pointer ml-2 primary px-3 py-2 radius-5" @click="openDefaultPlan">
        <span class="font-22 font-500 white--text">Edit Default Plan</span>
        </div> -->
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="data"
      class="elevation-1 mt-5"
      mobile-breakpoint=""
    >
      <template v-slot:item.type="{ item }">
        <span class="primary--text font-600 font-20">{{
          item.package ? item.package.type : ""
        }}</span>
      </template>
      <template v-slot:item.product="{ item }">
        <v-row class="py-3">
          <v-col cols="12">
            <v-row class="text-center">
              <v-col cols="3">Products</v-col>
              <v-col cols="3">
                <span class="font-14">Monthly</span>
              </v-col>
              <v-col cols="3">
                <span class="font-14">Yearly</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            v-for="(product, i) in item.pricing.products"
            :key="i"
          >
            <v-row class="text-center">
              <v-col cols="3">
                <div class="lightprimary d-inline-block px-3 py-1 radius-15">
                  <span class="font-14"> {{ product.itemsAllowed }}</span>
                </div>
              </v-col>
              <v-col cols="3">
                <span class="font-14">{{ product.pricePerMonth || 0 }}</span>
              </v-col>
              <v-col cols="3">
                <span class="font-14">{{ product.pricePerYear || 0 }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.preview="{ item }">
        <v-row class="py-3">
          <v-col cols="12">
            <v-row class="text-center">
              <v-col cols="3">Previews</v-col>
              <v-col cols="3">
                <span class="font-14">Monthly</span>
              </v-col>
              <v-col cols="3">
                <span class="font-14">Yearly</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            v-for="(preview, i) in item.pricing.previews"
            :key="i"
          >
            <v-row class="text-center">
              <v-col cols="3">
                <div class="lightprimary d-inline-block px-3 py-1 radius-15">
                  <span class="font-14"> {{ preview.screensAllowed }}</span>
                </div>
              </v-col>
              <v-col cols="3">
                <span class="font-14">{{ preview.pricePerMonth || 0 }}</span>
              </v-col>
              <v-col cols="3">
                <span class="font-14">{{ preview.pricePerYear || 0 }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch
          class="mt-0 mr-0"
          :hide-details="true"
          v-model="item.active"
          inset
          @change="switchHandler($event, item)"
        ></v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center justify-center">
          <img
            class="mx-2 cursor-pointer"
            :src="require('@/assets/images/icons/eye-black.svg')"
            @click="viewHandler(item)"
          />
          <img
            @click="editHandler(item)"
            class="mx-2 cursor-pointer"
            :src="require('@/assets/images/icons/setting-black.svg')"
          />
          <img
            @click="deleteHandler(item)"
            class="mx-2 cursor-pointer"
            :src="require('@/assets/images/icons/delete-black.svg')"
          />
        </div>
      </template>
    </v-data-table>

    <!-- add/edit new -->
    <v-dialog v-model="planDialog" max-width="450px">
      <new-plan
        @close="planDialog = false"
        @getData="getDataDialog"
        :isEdit="isEdit"
        :item="selectedItem"
        :key="planDialog"
      ></new-plan>
    </v-dialog>

    <!-- view item -->
    <v-dialog
      v-model="viewPlan"
      max-width="350px"
      content-class="white radius-15 "
    >
      <view-plan @close="viewPlan = false" :item="selectedItem"></view-plan>
    </v-dialog>
    <!-- delete item -->
    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-dialog
        @close="deleteDialog = false"
        :urlApi="plansApiUrl"
        :itemID="selectedItem._id"
        @getData="getData"
      ></delete-dialog>
    </v-dialog>
  </div>
</template>

<script>
import NewPlan from "../../../components/dialogs/NewPlan.vue";
import ViewPlan from "../../../components/dialogs/ViewPlan.vue";
export default {
  components: { NewPlan, ViewPlan },
  data: () => ({
    planDialog: false,
    defaultPlan: false,
    deleteDialog: false,
    viewPlan: false,
    packages: [],
    headers: [
      {
        text: "Plan Title",
        align: "start",
        sortable: false,
        value: "title",
        width: "10%",
      },
      { text: "Product Price", value: "product", width: "30%" },
      { text: "Preview Price", value: "preview", width: "30%" },
      // { text: "Package Type", value: "type" },
      { text: "Active", value: "active" },
      { text: "Actions", value: "actions", align: "center" },
    ],
    data: [],
    isEdit: false,
    selectedItem: {},
  }),
  methods: {
    async switchHandler(event, item) {
      await this.$axios.patch(`${process.env.VUE_APP_PLANS}/${item._id}`, {
        active: event,
      });
      if (event) {
        this.$store.dispatch("showSnack", {
          text: "plan is active",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: "plan is not active",
          color: "error",
        });
      }
    },
    async getData() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PLANS);
      if (data.success) {
        this.data = data.plans;
      }
    },
    async getPackages() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PACKAGES);
      if (data.success) this.packages = data.packages;
      //console.log(this.packages, 111);
    },
    async getDataDialog() {
      this.planDialog = false;
      this.isEdit = false;
      await this.getData();
    },
    newPlan() {
      this.planDialog = true;
      this.isEdit = false;
      this.selectedItem = {};
    },
    editHandler(item) {
      this.isEdit = true;
      this.planDialog = true;
      this.selectedItem = item;
    },
    deleteHandler(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },
    viewHandler(item) {
      this.viewPlan = true;
      this.selectedItem = item;
    },

    getProductNumberFromPackageId(id, productId) {
      return (
        this.packages
          .find((p) => p?._id == id)
          ?.numberOfProducts.find((p) => p?._id == productId)?.number || 0
      );
    },
    getPreviewNumberFromPackageId(packageId, previewId) {
      return (
        this.packages
          .find((p) => p?._id == packageId)
          ?.numberOfPreviews.find((p) => p?._id == previewId)?.number || 0
      );
    },
    // openDefaultPlan() {
    //   // this.isEdit = true;
    //   // this.selectedItem = {
    //   // }
    // }
  },
  computed: {
    plansApiUrl() {
      return process.env.VUE_APP_PLANS;
    },
  },
  async created() {
    await this.getData();
    await this.getPackages();
  },
  beforeRouteEnter(_, _2, next) {
    if (localStorage.getItem("role") == "admin") {
      next();
    } else {
      next("/");
    }
  },
};
</script>

<style lang="scss">
.admin-page {
  padding: 0px 4%;
}

.v-data-table-header {
  background: $primary;

  span {
    color: white;
    font-size: 18px;
    font-weight: 400;
  }
}

.item-counts {
  span {
    padding: 5px 10px;
    background: #d2e8e3;
    border-radius: 16px;
  }
}
</style>
