<template>
  <div>
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">{{ isEdit ? "Edit Plan" : "Add New Plan" }}</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>
    <v-form ref="planForm">
      <div class="bg-grey px-10 py-6">
        <v-text-field
          v-model="requestData.title"
          label="Plan Title"
          :hide-details="true"
        >
        </v-text-field>

        <v-select
          class="mt-5"
          label="Package type"
          :items="packages"
          v-model="requestData.package"
          item-text="type"
          item-value="_id"
          @change="selectPackage"
        ></v-select>

        <v-row v-if="requestData.package">
          <v-col cols="12">
            <v-row class="text-center">
              <v-col cols="3">
                <span class="font-14">No. of products</span>
              </v-col>
              <v-col cols="4">
                <span class="font-14">Monthly</span>
              </v-col>
              <v-col cols="4">
                <span class="font-14">Yearly</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            v-for="(pckage, i) in selectedPackage.numberOfProducts"
            :key="i"
          >
            <v-row class="my-3">
              <v-col cols="3">
                <div class="lightprimary d-inline-block px-3 py-1 radius-15">
                  <span class="font-14">{{ pckage.number }}</span>
                </div>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  placeholder=""
                  label=""
                  v-model="pckage.pricePerMonth"
                  dense
                  :hide-details="true"
                  :rules="[(v) => !!v || '']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  placeholder=""
                  label=""
                  v-model="pckage.pricePerYear"
                  dense
                  :hide-details="true"
                  :rules="[(v) => !!v || '']"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="4">
                <v-select
                  class="ma-0 pa-0"
                  :items="selectedPackage.numberOfPreviews"
                  @change="selectPackage"
                  v-model="pckage.numberOfPreviewsId"
                  item-text="number"
                  item-value="_id"
                ></v-select>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="requestData.package">
          <v-col cols="12">
            <v-row class="text-center">
              <v-col cols="3">
                <span class="font-14">No. of previews</span>
              </v-col>
              <v-col cols="4">
                <span class="font-14">Monthly</span>
              </v-col>
              <v-col cols="4">
                <span class="font-14">Yearly</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            v-for="(pckage, i) in selectedPackage.numberOfPreviews"
            :key="i"
          >
            <v-row class="my-3">
              <v-col cols="3">
                <div class="lightprimary d-inline-block px-3 py-1 radius-15">
                  <span class="font-14">{{ pckage.number }}</span>
                </div>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  placeholder=""
                  label=""
                  v-model="pckage.pricePerMonth"
                  dense
                  :hide-details="true"
                  :rules="[(v) => !!v || '']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  placeholder=""
                  label=""
                  v-model="pckage.pricePerYear"
                  dense
                  :hide-details="true"
                  :rules="[(v) => !!v || '']"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="4">
                <v-select
                  class="ma-0 pa-0"
                  :items="selectedPackage.numberOfPreviews"
                  @change="selectPackage"
                  v-model="pckage.numberOfPreviewsId"
                  item-text="number"
                  item-value="_id"
                ></v-select>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>

        <div class="my-6">
          <span>Enable Offers Page?</span>
          <span>
            <v-switch
              class="mt-0 mr-0"
              :hide-details="true"
              v-model="requestData.isOfferPage"
              inset
            ></v-switch>
          </span>
        </div>
        <!-- features -->
        <v-text-field
          v-model="feature"
          @keypress.enter="addFeatureHandler"
          class="mt-5"
          label="Add Features"
          :hide-details="true"
        >
        </v-text-field>
        <div class="mt-3">
          <div
            class="d-flex align-center mb-2"
            v-for="(featur, i) in requestData.features"
            :key="i"
          >
            <span class="item-counts d-inline-block mr-2 font-14">
              {{ featur }}
            </span>
            <img
              class="cursor-pointer"
              width="10"
              height="14px"
              v-if="featur"
              @click="removeFeature(i)"
              :src="require('@/assets/images/icons/close.svg')"
            />
          </div>
        </div>
        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          @click="addItem"
          ><span class="white--text font-16">{{
            isEdit ? "Update" : "Add"
          }}</span></v-btn
        >
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    features: [],
    // valid: '',
    selectedPackage: [],
    packages: [],
    requestData: { features: [], pricing: {} },

    feature: "",
  }),
  methods: {
    addFeatureHandler() {
      this.requestData.features.push(this.feature);
      this.feature = "";
    },
    removeFeature(idx) {
      this.requestData.features = [
        ...this.requestData.features.filter((c, i) => idx != i),
      ];
    },
    async addItem() {
      // check package
      if (this.selectedPackage.length == 0) {
        this.$store.dispatch("showSnack", {
          text: "Please select package",
          color: "error",
        });
        return;
      }
      // check feature
      if (this.requestData.features.length == 0) {
        this.$store.dispatch("showSnack", {
          text: "one feature is require at least",
          color: "error",
        });
        return;
      }
      // add pricing for products

      // check validate
      // let valid = this.$refs.planForm.validate();
      // if (!valid) return;
      // //console.log(this.selectedPackage.numberOfProducts);
      let res;
      // this.requestData.pricing = this.selectedPackage.numberOfProducts.map(
      //   (el) => {
      //     return {
      //       numberOfProductsId: el._id,
      //       pricePerMonth: el.pricePerMonth,
      //       pricePerYear: el.pricePerYear,
      //       numberOfPreviewsId: el.numberOfPreviewsId,
      //     };
      //   }
      // );

      this.requestData.pricing = {
        products: this.selectedPackage.numberOfProducts.map((product) => ({
          id: product._id,
          pricePerMonth: String(product.pricePerMonth),
          pricePerYear: String(product.pricePerYear),
        })),
        previews: this.selectedPackage.numberOfPreviews.map((preview) => ({
          id: preview._id,
          pricePerMonth: String(preview.pricePerMonth),
          pricePerYear: String(preview.pricePerYear),
        })),
      };

      if (this.isEdit) {
        res = await this.$axios.patch(
          `${process.env.VUE_APP_PLANS}/${this.item._id}`,
          {
            ...this.requestData,
          }
        );
        if (res.status == 200) {
          this.userDoUpdate = true;
          this.$emit("getData");
          this.$store.dispatch("showSnack", {
            text: "Updated Successfully",
            color: "success",
          });
        } else {
          this.$store.dispatch("showSnack", {
            text:
              res?.message ||
              "something wrong on server , please try again later",
            color: "error",
          });
        }
      } else {
        res = await this.$axios.post(process.env.VUE_APP_PLANS, {
          ...this.requestData,
        });
        if (res.data) {
          this.$emit("getData");
          this.$store.dispatch("showSnack", {
            text: "Added Successfully",
            color: "success",
          });
          this.$emit("getData");
        } else {
          this.$store.dispatch("showSnack", {
            text: res.message,
            color: "error",
          });
        }
      }
    },
    selectPackage() {
      this.selectedPackage = this.packages.filter(
        (pckage) =>
          pckage._id == this.requestData.package._id ||
          pckage._id == this.requestData.package
      )[0];
      //console.log(this.selectedPackage, 70222222222222229, this.requestData);
      // this.selectedPackage.numberOfProducts = this.selectedPackage
      //   .numberOfProducts[0]?.numberOfProducts
      //   ? this.selectedPackage.numberOfProducts
      //   : this.selectedPackage.numberOfProducts.map((num) => {
      //       return {
      //         numberOfProducts: num,
      //         pricePerMonth: 0,
      //         pricePerYear: 0,
      //         numberOfPreviewsId: 0,
      //       };
      //     });

      this.selectedPackage.numberOfProducts = this.selectedPackage
        .numberOfProducts[0]?.numberOfProducts
        ? this.selectedPackage.numberOfProducts
        : this.selectedPackage.numberOfProducts.map((num) => {
            //console.log(num, "num");
            return {
              ...num,
              pricePerMonth: this.requestData.pricing.products?.length
                ? this.requestData.pricing.products.find(
                    (product) => product.id === num._id
                  )?.pricePerMonth
                : 0,

              pricePerYear: this.requestData.pricing.products?.length
                ? this.requestData.pricing.products.find(
                    (product) => product.id === num._id
                  )?.pricePerYear
                : 0,
            };
          });

      this.selectedPackage.numberOfPreviews = this.selectedPackage
        .numberOfPreviews[0]?.numberOfPreviews
        ? this.selectedPackage.numberOfPreviews
        : this.selectedPackage.numberOfPreviews.map((num) => {
            //console.log(num, "num");
            return {
              ...num,
              pricePerMonth: this.requestData.pricing.previews?.length
                ? this.requestData.pricing.previews.find(
                    (preview) => preview.id === num._id
                  )?.pricePerMonth
                : 0,
              pricePerYear: this.requestData.pricing.previews?.length
                ? this.requestData.pricing.previews.find(
                    (preview) => preview.id === num._id
                  )?.pricePerYear
                : 0,
            };
          });
    },
    async getPackages() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PACKAGES);
      if (data.success) {
        this.packages = data.packages;
      }
    },
  },
  async created() {
    await this.getPackages();
    if (this.isEdit) {
      this.requestData = this.$structuredClone(this.item);
      //console.log(this.item, 7676);
      //console.log(this.requestData);
      this.selectPackage();
    }
    ////console.log(this.selectedPackage.numberOfProducts);
  },
  destroyed() {
    this.requestData = { features: [], pricing: {} };
  },
};
</script>

<style lang="scss" scoped>
.item-counts {
  padding: 5px 10px;
  background: #d2e8e3;
  border-radius: 16px;
}
</style>
